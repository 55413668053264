import { TypeOf, compile, v } from 'suretype';

const PolygonCoordinates = v.array(v.array(v.array(v.number())));

const riskParcelAllMetrics = v.object({
    id: v.number().integer().required(),
    taxParcelNumber: v.string().required(),
    adjustedParcelScore: v.number().integer().required(),
    streetAddress: v.string().required(),
    cityState: v.anyOf([v.string(), v.null()]).required(),
    zipCode: v.string().required(),
    landValue: v.number().required(),
    landUseDescription: v.string().required(),
    geometry: v.anyOf([
        v.object({
            type: v.string().enum('Polygon').required(),
            coordinates: PolygonCoordinates.required(),
        }),
        v.object({
            type: v.string().enum('MultiPolygon').required(),
            coordinates: v.array(PolygonCoordinates).required(),
        }),
    ]).required(),
    buildings: v.array(v.object({
        buildingType: v.string().required(),
        constructionType: v.string().required(),
        constructionScore: v.number().required(),
        occupancyType: v.string().required(),
        occupancyScore: v.number().required(),
        sprinklerCoverage: v.string().required(),
        sprinklerScore: v.number().required(),
        squareFeet: v.anyOf([v.number(), v.null()]).required(),
        squareFeetScore: v.number().required(),
        stories: v.number().required(),
        storiesScore: v.number().required(),
        totalBuildingScore: v.number().required(),
        yearBuilt: v.number().required(),
        yearScore: v.number().required()
    })).required(),
});

export const validateRiskParcelAllMetrics = compile(riskParcelAllMetrics, { ensure: true });
export type RiskParcelAllMetrics = TypeOf<typeof riskParcelAllMetrics>;
