import { format as d3Format } from 'd3';
import { BILLION, MILLION, TRILLION } from './constants';

const formatWithCommas = d3Format(',');

/**
 * Format the specified value as a currency string.
 *
 * All values are rounded to the nearest whole number, prefixed with '$' and
 * use ',' for thousands separators.
 *
 * Further rounding and suffixes like M, B, T are applied based on hard-coded thresholds
 * (see implementation for exact values). For example:
 *     1_234_567 => $1.2M
 *
 * @param v The value to format
 * @param format An object with the following keys:
 *               abbreviated   : If false, only round to the nearest whole number
 *                               and ignore minNonFuzzy and minUsingSuffix
 *               minNonFuzzy   : Values below this threshold are formatted as "<$v"
 *               minUsingSuffix: Values below this threshold are formatted as "$v"
 *                               (i.e. without units like M, B, T)
 * @param undefinedLabel The string to return if v is undefined
 *
 * @returns The formatted value
 */
export function fmtCurrency(
    v: number | null | undefined,
    format: {
        abbreviated: false
    } | {
        abbreviated: true,
        minNonFuzzy: number,
        minUsingSuffix: number,
    },
    undefinedLabel = '',
): string {
    if (v === undefined || v === null) {
        return undefinedLabel;
    }

    if (v === 0) {
        return '$0';
    }

    if (!format.abbreviated) {
        const withCommas = formatWithCommas(Math.round(v));
        return `$${withCommas}`;
    }

    if (v < format.minNonFuzzy) {
        const withCommas = formatWithCommas(format.minNonFuzzy);
        return `<$${withCommas}`;
    }

    const roundedV = getRoundedValue(v);

    if (roundedV < format.minUsingSuffix) {
        const withCommas = formatWithCommas(roundedV);
        return `$${withCommas}`;
    }

    const valueWithSuffix = getValueWithSuffix(roundedV);

    return `$${valueWithSuffix}`;
}

function getRoundedValue(unroundedValue: number): number {
    /* eslint-disable no-multi-spaces, comma-spacing */
    const roundingThresholds = [
        { minValue: 10 * TRILLION, roundTo: 1   * TRILLION },
        { minValue: 1  * TRILLION, roundTo: 100 * BILLION },
        { minValue: 10 * BILLION , roundTo: 1   * BILLION },
        { minValue: 1  * BILLION , roundTo: 100 * MILLION },
        { minValue: 10 * MILLION , roundTo: 1   * MILLION },
        { minValue: 1  * MILLION , roundTo: 100_000 },
        { minValue: 1_000        , roundTo: 1_000 },
        { minValue: 0            , roundTo: 1 },
    ];
    /* eslint-enable no-multi-spaces, comma-spacing */

    const threshold = roundingThresholds.find(
        ({ minValue }) => unroundedValue >= minValue
    );

    const roundTo = threshold?.roundTo ?? 1;
    const roundedValue = Math.round(unroundedValue / roundTo) * roundTo;

    return roundedValue;
}

function getValueWithSuffix(value: number): string {
    /* eslint-disable no-multi-spaces, comma-spacing */
    const suffixThresholds = [
        { minValue: 1  * TRILLION, divisor: 1 * TRILLION, unit: 'T' },
        { minValue: 1  * BILLION , divisor: 1 * BILLION , unit: 'B' },
        { minValue: 1  * MILLION , divisor: 1 * MILLION , unit: 'M' },
        { minValue: 1_000        , divisor: 1_000       , unit: 'k' },
        { minValue: 0            , divisor: 1           , unit: '' },
    ];
    /* eslint-enable no-multi-spaces, comma-spacing */

    const threshold = suffixThresholds.find(
        ({ minValue }) => value >= minValue
    );

    const divisor = threshold ? threshold.divisor : 1;
    const suffix = threshold?.unit ?? '';

    const withCommas = formatWithCommas(value / divisor);
    const valueWithSuffix = `${withCommas}${suffix}`;

    return valueWithSuffix;
}
